<template>
    <div>
        <v-app>
            <navigation :color="color" :flat="flat" :textColor="textColor" :logoCompleto="logoCompleto" />
            <v-main>
                <v-carousel hide-delimiters :show-arrows="false" v-if="itemsDoctors.doctors_banner">
                    <v-carousel-item>
                        <v-img :src="itemsDoctors.doctors_banner.url" contain />
                    </v-carousel-item>
                </v-carousel>

                <v-container
                    v-if="itemsDoctors.doctors_items"
                >
                    <v-row
                        v-for="(item, i) in itemsDoctors.doctors_items"
                        :key="i"
                        justify="center"
                    >
                        <v-col cols="12" sm="12" md="3">
                            <v-img class="mx-auto" :src="item.url"></v-img>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                            <div>
                                <p class="text-h2 accent--text font-weight-bold text-uppercase text-left">
                                    {{ item.name }}
                                </p>
                                <p class="text-h5 grey--text text--darken-2 font-weight-bold text-uppercase text-left">
                                    Cédula: {{ item.medical_license }}
                                </p>
                                <p class="text-h6 accent--text font-weight-bold text-uppercase text-left">
                                    Especialidad: <span class="grey--text text--darken-2">{{ item.specialty }}</span>
                                </p>
                                <p class="text-h6 accent--text font-weight-bold text-uppercase text-left">
                                    Cel: <span class="grey--text text--darken-2"><a :href="`tel:+52${item.cellphone}`">{{ item.cellphone }}</a></span>
                                </p>
                                <p class="text-h6 accent--text font-weight-bold text-uppercase text-left">
                                    Tipo de medicina: <br><span class="grey--text text--darken-2">{{ item.type_medicine }}</span>
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>

            </v-main>

            <v-scale-transition>
                <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
                    <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
            </v-scale-transition>
            <customFooter></customFooter>
        </v-app>
    </div>
</template>
  
<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import AOS from 'aos';
import navigation from "@/modules/landing/components/Navigation.vue";
import customFooter from "@/modules/shared/layouts/Footer.vue";

export default {
    name: "Nosotros",
    components: {
        navigation,

        customFooter,
    },

    data: () => ({
        logoCompleto: null,
        fab: null,
        color: "primary",
        textColor: "white",
        flat: null,
        search: "",
        autocompleteResults: [],
        selectedItem: 0,
        currentCategoria: {
            children: []
        },
        itemsCarousel: [
            {
                src: require('@/assets/img/banner-medicos.jpg'),
            },
        ],
    }),

    created() {
        this.getItemsDoctors();

        const top = window.pageYOffset || 0;
        if (top <= 60) {
            this.color = "primary";
            this.flat = true;
        }
    },
    computed: {
        ...mapState("loader", ["loading"]),
        ...mapState("landing", ["itemsDoctors"]),
    },
    mounted() {
        // Actualiza AOS después de que Vue ha renderizado el componente
        this.$nextTick(() => {
            AOS.refresh();
        });
    },
    methods: {
        ...mapActions({
            getItemsDoctors: "landing/getItemsDoctors",
        }),
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 60;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        setCategoria(item) {
            this.currentCategoria = item;
        },
        getAutocompleteResults(query) {
            let me = this;
            return axios
            .get(`faq.php?query=${query}`)
            .then((response) => {
                console.log("getAutocompleteResults: ", response);
                if (response.data) {
                    this.autocompleteResults = response.data;
                }
            })
            .catch(function (err) {
                console.log("err:", err);
                me.$toast.error(`Ha ocurrido un problema al cargar al listado`);
            });
        },
    },
    watch: {
        fab(value) {
            if (value) {
                this.color = "dark";
                this.textColor = "white";
                this.flat = false;
                this.logoCompleto = false;
            } else {
                this.color = "primary";
                this.textColor = "white";
                this.flat = true;
                this.logoCompleto = true;
            }
        },
        search: function(newVal, oldVal) {
            console.log(oldVal);
            // Llamar a la función de autocompletado cada vez que cambie el valor de búsqueda
            if (newVal.length >= 5) {
                this.getAutocompleteResults(newVal);
            } else {
                // Si la longitud del término de búsqueda es menor a 5, limpiar los resultados
                this.autocompleteResults = [];
            }
        },
    }
};
</script>
  
<style scoped>
#app {
    background-image: url("~@/assets/img/home_background.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    z-index: 10;
}
</style>